import type { JSX } from 'react';

import { Translate } from '@change-corgi/core/react/i18n';
import { ButtonLink } from '@change-corgi/design-system/components/actions';
import { Box } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import { usePetitionDetailsPageUserData } from 'src/app/pages/petitionGamma/details/pageContext';

import { usePetitionSignatureCount } from '../../hooks/petitionStats';
import { useNextStepButtonAction } from '../../hooks/useNextStepButtonAction';
import { SignatureStatsActiveContainer } from '../stats/signatureStats';

export function SideSignedPromotableContainer(): JSX.Element {
	const { signatureTargetLocalized } = usePetitionSignatureCount();
	const {
		data: { comboLink },
		action: { handleNextStepClick },
	} = useNextStepButtonAction();

	const {
		data: { signatureThermometerEnabled },
	} = usePetitionDetailsPageUserData();

	return (
		<Box>
			<Box my={16}>
				<SignatureStatsActiveContainer />
			</Box>
			{!signatureThermometerEnabled && (
				<Box
					backgroundColor="neutral-grey50"
					sx={{ borderRadius: 'standard' }}
					p={16}
					my={16}
					data-testid="combo-action-text"
				>
					<Text as="div" size="small">
						<Translate
							value="fe.pages.petition_update.combo_call_to_action_identified"
							replacements={{ signature_target: signatureTargetLocalized }}
						/>
					</Text>
				</Box>
			)}
			<ButtonLink
				to={comboLink}
				sx={{ width: '100%' }}
				data-qa="signed-petition-combo-cta"
				onClick={handleNextStepClick}
			>
				<Translate value="fe.pages.petition_update.combo_action_button_label" />
			</ButtonLink>
		</Box>
	);
}
