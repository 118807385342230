import { Translate } from '@change-corgi/core/react/i18n';
import { Icon } from '@change-corgi/design-system/components/icon';
import { ProgressIndicator } from '@change-corgi/design-system/components/navigation';
import { iconFlag } from '@change-corgi/design-system/icons';
import { Flex } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

export const SIGNATURE_MILESTONES = [10, 25, 50, 75, 100, 125, 150, 175, 200, 250, 300, 350, 400, 450, 500, 750, 1000];

type Props = {
	signatureCount: number;
};

export const getNextMilestone = (signatureCount: number): number | undefined => {
	return SIGNATURE_MILESTONES.find((milestone) => milestone > signatureCount);
};

export const SignatureThermometer = ({ signatureCount }: Props): JSX.Element | null => {
	const nextMilestone = getNextMilestone(signatureCount);

	if (!nextMilestone) return null;

	return (
		<Flex
			data-testid="signature-thermometer-container"
			sx={{
				backgroundColor: 'rgba(21, 125, 185, 0.05)',
				borderRadius: 8,
				flexDirection: 'column',
				gap: 8,
				paddingY: 14,
				paddingX: 12,
			}}
		>
			<Flex sx={{ gap: 8 }}>
				<Flex sx={{ backgroundColor: 'rgba(0, 107, 169, 0.1)', borderRadius: 12, padding: 5, width: 24, height: 24 }}>
					<Icon
						size="s"
						icon={iconFlag}
						data-testid="signatures-milestone-flag-icon"
						sx={{
							color: 'secondary-blue',
						}}
					/>
				</Flex>
				<Text size="small" fontWeight="bold">
					<Translate
						value="corgi.petition_gamma.details.signature_thermometer.title"
						replacements={{ nextMilestone }}
					/>
				</Text>
			</Flex>

			<ProgressIndicator
				variant="blue"
				completionPercentage={Math.floor((signatureCount / nextMilestone) * 100)}
				sx={{ height: 12 }}
			/>
			<Text size="small">
				<Translate value="corgi.petition_gamma.details.signature_thermometer.body" />
			</Text>
		</Flex>
	);
};
